// guided tour events
export enum TOUR_ELEMENTS {
  TB_HELP = "TB_HELP",
  TB_USER = "TB_USER",
  TB_COMBINE_MENU = "TB_COMBINE_MENU",
  TB_FAVORITES = "TB_FAVORITES",
  FAV_DETAILS = "FAV_DETAILS"
}

export interface ITourStep {
  element: string;
  content_url: string;
  needs: TOUR_ELEMENTS[];
  route: string;
}