import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IEventCleanup, LANGUAGE_TYPE_LOCALE, TOUR_COMPONENTS } from "./common.enum";
import { ACTION, IEventRequest, SITE_VIEW } from "../site/site.interface";
import {
  dashboard_steps,
  favorite_steps,
  toolbar_steps
} from "./guidedTour.steps";
import { SiteEventService } from "../site/site.event.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { ITourStep, TOUR_ELEMENTS } from "./guidedTour.interface";
import Shepherd from "shepherd.js";

@Injectable()

export class GuidedTourService {

  private interactive_help_active: boolean = false;
  private evt_cleanup_arr: IEventCleanup[] = [];
  private tb_loaded: boolean = false;
  private db_loaded: boolean = false;
  private sm_loaded: boolean = false;
  private zm_loaded: boolean = false;
  private start_tour_component_async: boolean = false;
  private current_tour_steps: ITourStep[] = [];
  private tour_options: any;
  private tour: any = null;
  private current_tour_index: number = -1;

  constructor(
    private translate_service: TranslateService,
    private event_service: SiteEventService,
    private router: Router,
    private http: HttpClient) {
    this.tour_options = {
      confirmCancel: false,
      exitOnEsc: true,
      keyboardNavigation: true,
      useModalOverlay: true,
      defaultStepOptions: {
        canClickTarget: false,
        cancelIcon: {
          enabled: true
        },
        classes: "tour-step",
        modalOverlayOpeningPadding: 0,
        modalOverlayOpeningRadius: 8,
        scrollTo: true,
        arrow: false
      }
    };

    // register application events
    this.event_service.site_event_listener.subscribe((event: IEventRequest) => {
      switch (event.type) {
        case ACTION.TOOLBAR_LOADED:
          this.tb_loaded = true;
          break;
        case ACTION.DASHBOARD_LOADED:
          this.db_loaded = true;
          break;
        case ACTION.SITE_MANAGER_LOADED:
          this.sm_loaded = true;
          break;
        case ACTION.ZONE_MANAGER_LOADED:
          this.zm_loaded = true;
          break;
        default:
          break;
      }
      if (this.tour && this.start_tour_component_async) {
        this.handle_step_needs([]);
        this.tour.start();
      }
    });
  }

  private initialize_tour(): void {
    this.current_tour_steps = [];
    if (this.tour && this.tour.isActive()) {
      this.tour.cancel();
    }
    this.tour = new Shepherd.Tour(this.tour_options);
    this.current_tour_index = -1;

    // register tour events
    ["start", "complete", "cancel"].forEach(event_type => this.tour.once(event_type, () => {
      switch (event_type) {
        case "start":
          // modify first and last steps' buttons
          if (this.interactive_help_active) {
            const step = this.tour.getById("0");
            if (step) {
              step.options.buttons = [{
                text: this.translate_service.instant("CLOSE"),
                action: this.tour.complete
              }];
            }
          } else {
            const first_step = this.tour.getById("0");
            if (first_step) {
              first_step.options.buttons = [{
                text: this.translate_service.instant("NEXT"),
                action: this.tour.next
              }];
            }
            const last_step = this.tour.getById(`${this.current_tour_steps.length - 1}`);
            if (last_step) {
              last_step.options.buttons = [{
                text: this.translate_service.instant("BACK"),
                action: this.tour.back
              }, {
                text: this.translate_service.instant("CLOSE"),
                action: this.tour.complete
              }];
            }
          }
          break;
        default:
          this.handle_step_needs([]);
          break;
      }
    }));
  }

  private handle_step_needs(step_needs: TOUR_ELEMENTS[]): void {
    let need_request_actions: ACTION[] = [ACTION.TB_CLOSE_COMBINE_MENU, ACTION.TB_CLOSE_USER, ACTION.TB_CLOSE_HELP,
    ACTION.TB_CLOSE_FAVORITES, ACTION.TB_CLOSE_FAVORITE_DETAILS];
    for (const need of step_needs) {
      switch (need) {
        case TOUR_ELEMENTS.TB_COMBINE_MENU:
          // combine menu needs to be opened
          need_request_actions.push(ACTION.TB_OPEN_COMBINE_MENU);
          need_request_actions = need_request_actions.filter(action => action !== ACTION.TB_CLOSE_COMBINE_MENU);
          break;
        case TOUR_ELEMENTS.TB_USER:
          // user menu needs to be opened
          need_request_actions.push(ACTION.TB_OPEN_USER);
          need_request_actions = need_request_actions.filter(action => action !== ACTION.TB_CLOSE_USER);
          break;
        case TOUR_ELEMENTS.TB_HELP:
          // help menu needs to be opened
          need_request_actions.push(ACTION.TB_OPEN_HELP);
          need_request_actions = need_request_actions.filter(action => action !== ACTION.TB_CLOSE_HELP);
          break;
        case TOUR_ELEMENTS.TB_FAVORITES:
          // favorites sidebar needs to be opened
          need_request_actions.push(ACTION.TB_OPEN_FAVORITES);
          need_request_actions = need_request_actions.filter(action => action !== ACTION.TB_CLOSE_FAVORITES);
          break;
        case TOUR_ELEMENTS.FAV_DETAILS:
          // favorite details need to be opened
          need_request_actions.push(ACTION.TB_OPEN_FAVORITE_DETAILS);
          need_request_actions = need_request_actions.filter(action => action !== ACTION.TB_CLOSE_FAVORITE_DETAILS);
          break;
        default:
          break;
      }
    }
    if (need_request_actions.length > 0) {
      // trigger some action on entering step
      const request: IEventRequest = {
        type: null,
        payload: null
      };
      for (const command of need_request_actions) {
        request.type = command;
        this.event_service.site_event(request);
      }
      // find correct step content
      if (this.interactive_help_active) {
        setTimeout(() => {
          this.get_and_setup_doms();
        }, 0);
      }
    }
  }

  public start_guided_tour(component: string): void {
    const tour_steps = this.get_component_tour_steps(component);
    if (tour_steps !== null && tour_steps.length > 0) {
      this.initialize_tour();
      this.start_tour_component_async = false;
      if (tour_steps[0] && tour_steps[0].route) {
        // check navigation to required route
        if (this.router.url !== tour_steps[0].route) {
          this.start_tour_component_async = true;
        }
      }
      this.process_step_request_sequence(tour_steps.filter(step => step.element !== "skipped_description_id"), !this.start_tour_component_async);
      if (this.start_tour_component_async) {
        // navigate to route and start tour afterwards
        this.router.navigate([tour_steps[0].route]);
      }
    }
  }

  private process_step_request_sequence(sequence_list: ITourStep[], start_tour: boolean): void {
    // process list
    if (sequence_list.length > 0) {
      const step = sequence_list[0];
      sequence_list = sequence_list.slice(1);
      this.request_step_content(step, sequence_list, start_tour);
    } else {
      // done
      if (start_tour) {
        this.handle_step_needs([]);
        this.tour.start();
      }
    }
  }

  private request_step_content(step: ITourStep, sequence_list: ITourStep[], start_tour: boolean): void {
    const searchRegExp = /..\/..\/Resources\//g;
    const replaceWith = "assets/help/Resources/";
    // check current sequence step
    const step_command_available = step;
    if (step_command_available && step_command_available.content_url) {
      // load step content for current language
      let language_link = "en";
      const language = localStorage.getItem("LANG");
      if (language === LANGUAGE_TYPE_LOCALE.DEU) {
        language_link = "de";
      }
      // dynamic step content currently only available for de
      this.http.get(`assets/help/${language_link}/${step_command_available.content_url}`, { responseType: "text" }).subscribe({
        next: (data) => {
          // replace step content with data
          let content = data;
          let title = "";
          const dom = new DOMParser().parseFromString(data, "text/html");
          const allowed_attributes_list = ["class", "src", "hidden"];
          if (dom) {
            // extract title
            const h1 = dom.querySelector("h1");
            if (h1) {
              h1.setAttribute("hidden", "true");
              title = h1.innerHTML;
            }
            // sanitize - remove unwanted attributes
            const ids = dom.getElementsByTagName("*");
            for (let i = 0; i < ids.length; i++) {
              const id = ids[i];
              const del_list = [];
              if (id.hasAttributes()) {
                for (let j = 0; j < id.attributes.length; j++) {
                  const attr = id.attributes[j];
                  if (!allowed_attributes_list.includes(attr.name)) {
                    del_list.push(attr.name);
                  }
                }
              }
              if (del_list.length > 0) {
                for (const del of del_list) {
                  id.removeAttribute(del);
                }
              }
            }
            content = dom.documentElement.innerHTML;
          }
          // add step to tour
          const step_id = `${this.current_tour_steps.length}`;
          this.tour.addStep({
            id: step_id,
            title,
            text: content.replace(searchRegExp, replaceWith),
            attachTo: {
              element: () => {
                return step.element === "no_element" ? null : document.getElementById(step.element);
              },
              on: "bottom-start"
            },
            buttons: [{
              text: this.translate_service.instant("BACK"),
              action: this.tour.back
            }, {
              text: this.translate_service.instant("NEXT"),
              action: this.tour.next
            }],
            highlightClass: this.interactive_help_active ? "tour-step-selected-interactive" : "tour-step-selected",
            beforeShowPromise: () => {
              return new Promise((resolve) => {
                const current_index = this.tour.steps.indexOf(this.tour.getCurrentStep());
                const direction = current_index > this.current_tour_index ? 1 : -1;
                this.current_tour_index = current_index;
                this.handle_step_needs(step.needs ? step.needs : []);
                const step_dom = step.element === "no_element" ? "no_element" : document.getElementById(step.element);
                if (!step_dom) {
                  setTimeout(() => {
                    const step_dom_recheck = step.element === "no_element" ? "no_element" : document.getElementById(step.element);
                    if (!step_dom_recheck) {
                      if (direction === 1) {
                        this.tour.next();
                      } else {
                        this.tour.back();
                      }
                    } else {
                      resolve(true);
                    }
                  }, 0);
                } else {
                  resolve(true);
                }
              });
            }
          });
          this.current_tour_steps.push(step);
          // further process sequence
          this.process_step_request_sequence(sequence_list, start_tour);
        },
        error: (err) => {
          // further process sequence
          this.process_step_request_sequence(sequence_list, start_tour);
        }
      });
    } else {
      // further process sequence
      this.process_step_request_sequence(sequence_list, start_tour);
    }
  }

  private get_component_tour_steps(component: string): ITourStep[] {
    let tour_steps = null;
    switch (component) {
      case TOUR_COMPONENTS.ALL:
        tour_steps = toolbar_steps.concat(dashboard_steps).concat(favorite_steps);
        break;
      case TOUR_COMPONENTS.TOOLBAR:
        tour_steps = toolbar_steps;
        break;
      case TOUR_COMPONENTS.FAVORITES:
        tour_steps = favorite_steps;
        break;
      case TOUR_COMPONENTS.DASHBOARD:
        tour_steps = dashboard_steps;
        break;
      case TOUR_COMPONENTS.SITE_MANAGER:
      case TOUR_COMPONENTS.ZONE_MANAGER:
      case TOUR_COMPONENTS.SITES:
      case TOUR_COMPONENTS.SITES_MAP:
      case TOUR_COMPONENTS.SITES_TABLE:
      case TOUR_COMPONENTS.SITES_GRAPH:
      case TOUR_COMPONENTS.SITES_DETAIL_VIEW:
      case TOUR_COMPONENTS.SITE_MANAGER:
      case TOUR_COMPONENTS.NETWORK_MANAGER:
      case TOUR_COMPONENTS.PIPE_PARAMETER:
      case TOUR_COMPONENTS.DASHBOARD_SETTINGS:
      case TOUR_COMPONENTS.MEASUREMENT_PROFILES:
      case TOUR_COMPONENTS.SITE_ALARMS:
      case TOUR_COMPONENTS.EXPORTS:
      case TOUR_COMPONENTS.DATE_TIME:
      case TOUR_COMPONENTS.USER_MANAGEMENT:
        // missing
        break;
      default:
        break;
    }
    return tour_steps;
  }

  public get_tour_components() {
    return TOUR_COMPONENTS;
  }

  public get_guided_tours_list_by_route(current_route: string): TOUR_COMPONENTS[] {
    const guided_tours_list: TOUR_COMPONENTS[] = [TOUR_COMPONENTS.TOOLBAR];
    if (current_route === "/app") {
      guided_tours_list.push(TOUR_COMPONENTS.DASHBOARD);
      // reset other components loading state
      this.sm_loaded = false;
      this.zm_loaded = false;
    } else if (current_route === "/app/sites") {
      /* guided_tours_list.push(TOUR_COMPONENTS.SITES);
      guided_tours_list.push(TOUR_COMPONENTS.SITES_MAP);
      guided_tours_list.push(TOUR_COMPONENTS.SITES_TABLE);
      guided_tours_list.push(TOUR_COMPONENTS.SITES_GRAPH);
      guided_tours_list.push(TOUR_COMPONENTS.SITES_DETAIL_VIEW);*/
      // reset other components loading state
      this.db_loaded = false;
      this.sm_loaded = false;
      this.zm_loaded = false;
    } else if (current_route.includes("/app/settings/")) {
      /* guided_tours_list.push(TOUR_COMPONENTS.SITE_MANAGER);
      guided_tours_list.push(TOUR_COMPONENTS.ZONE_MANAGER);
      guided_tours_list.push(TOUR_COMPONENTS.NETWORK_MANAGER);
      guided_tours_list.push(TOUR_COMPONENTS.PIPE_PARAMETER);
      guided_tours_list.push(TOUR_COMPONENTS.DASHBOARD_SETTINGS);
      guided_tours_list.push(TOUR_COMPONENTS.MEASUREMENT_PROFILES);
      guided_tours_list.push(TOUR_COMPONENTS.SITE_ALARMS);
      guided_tours_list.push(TOUR_COMPONENTS.EXPORTS);
      guided_tours_list.push(TOUR_COMPONENTS.DATE_TIME);
      guided_tours_list.push(TOUR_COMPONENTS.USER_MANAGEMENT);*/
      // reset other components loading state
      this.db_loaded = false;
    }
    guided_tours_list.push(TOUR_COMPONENTS.FAVORITES);
    return guided_tours_list;
  }

  public check_is_component_loaded(component: TOUR_COMPONENTS): boolean {
    let component_loaded = false;
    switch (component) {
      case TOUR_COMPONENTS.TOOLBAR:
        component_loaded = this.tb_loaded;
        break;
      case TOUR_COMPONENTS.DASHBOARD:
        component_loaded = this.db_loaded;
        break;
      case TOUR_COMPONENTS.FAVORITES:
        component_loaded = this.tb_loaded;
        break;
      case TOUR_COMPONENTS.SITE_MANAGER:
        component_loaded = this.sm_loaded;
        break;
      case TOUR_COMPONENTS.ZONE_MANAGER:
        component_loaded = this.zm_loaded;
        break;
      default:
        break;
    }
    return component_loaded;
  }

  public get_guided_tour_name(component: TOUR_COMPONENTS): string {
    let language_key = component.toString();
    switch (component) {
      case TOUR_COMPONENTS.SITES_MAP:
        language_key = SITE_VIEW.MAP;
        break;
      case TOUR_COMPONENTS.SITES_TABLE:
        language_key = SITE_VIEW.TABLE;
        break;
      case TOUR_COMPONENTS.SITES_GRAPH:
        language_key = SITE_VIEW.GRAPH;
        break;
      case TOUR_COMPONENTS.SITES_DETAIL_VIEW:
        language_key = "DETAIL_VIEW";
        break;
      case TOUR_COMPONENTS.PIPE_PARAMETER:
        language_key = "NETWORK_PREFERENCE";
        break;
      case TOUR_COMPONENTS.DASHBOARD_SETTINGS:
        language_key = "DASHBOARD";
        break;
      case TOUR_COMPONENTS.MEASUREMENT_PROFILES:
        language_key = "MEAS_PROFILES";
        break;
      case TOUR_COMPONENTS.FAVORITES:
        language_key = "FAVORITE";
        break;
      default:
        break;
    }
    return this.translate_service.instant(language_key);
  }

  public get_guided_tour_description(component: TOUR_COMPONENTS): string {
    let tour_description = "";
    const tour_steps = this.get_component_tour_steps(component);
    if (tour_steps !== null && tour_steps.length > 0) {
      const desc_step = tour_steps.find(step => step.element === "skipped_description_id");
      if (desc_step) {
        tour_description = desc_step.content_url;
        // get description for current language
        let lang_key = "en:";
        const language = localStorage.getItem("LANG");
        if (language === LANGUAGE_TYPE_LOCALE.DEU) {
          lang_key = "de:";
        }
        if (tour_description.includes("};{")) {
          const tour_desc = tour_description.split("};{");
          for (const desc of tour_desc) {
            if (desc.includes(lang_key)) {
              tour_description = desc.replace(`${lang_key}`, "").replace(/{/, "").replace(/}/, "");
              break;
            }
          }
        }
      }
    }
    return tour_description;
  }

  public toggle_interactive_mode(set_val?: boolean): void {
    this.interactive_help_active = set_val !== undefined ? set_val : !this.interactive_help_active;
    // clean up always before
    for (const event_entry of this.evt_cleanup_arr) {
      // unregister additional click event
      event_entry.element.removeEventListener("click", event_entry.method);
      // remove highlighting class
      event_entry.element.classList.remove("tour-step-available");
    }
    this.evt_cleanup_arr = [];
    this.get_and_setup_doms();
  }

  private get_and_setup_doms(): void {
    if (this.interactive_help_active) {
      // mode activated or updated - register new events and add class, store in array for later clean up
      const all_step_ids = this.get_component_tour_steps(TOUR_COMPONENTS.ALL).filter(step => step.element && !(step.element === "skipped_description_id" ||
        step.element === "no_element")).map(step => step.element);
      for (const dom_id of all_step_ids) {
        const dom = document.getElementById(dom_id);
        if (dom) {
          if (!dom.classList.contains("tour-step-available")) {
            dom.classList.add("tour-step-available");
            // register additional click event
            const event_method = () => this.show_help_step_by_dom_id(dom_id);
            dom.addEventListener("click", event_method, { passive: true });
            this.evt_cleanup_arr.push({
              element: dom,
              method: event_method
            });
          }
        }
      }
    }
  }

  private show_help_step_by_dom_id(dom_id: string): void {
    // find correct step content
    if (this.interactive_help_active) {
      this.initialize_tour();
      const step = this.get_component_tour_steps(TOUR_COMPONENTS.ALL).find(step => step.element === dom_id);
      if (step) {
        this.process_step_request_sequence([step], true);
      }
    } else {
      console.warn("interactive help inactive but event still registered!");
    }
  }

  public check_interactive_help_active(): boolean {
    return this.interactive_help_active;
  }
}
