import { ITourStep, TOUR_ELEMENTS } from "./guidedTour.interface";

// toolbar steps
export const toolbar_steps: ITourStep[] = [
  // toolbar - special steps
  {
    element: "skipped_description_id",
    content_url: "{de:Erklärung aller wichtigen Elemente des Menüleiste.};{en:Explanation of all important elements of the menu bar.}",
    needs: [],
    route: null
  },
  // toolbar - content and commands
  {
    element: "no_element",
    content_url: "dashboard/menu_tour_intro.htm",
    needs: [],
    route: null
  }, {
    element: "toolbar_main_nav",
    content_url: "dashboard/menu_bar.htm",
    needs: [],
    route: null
  }, {
    element: "toolbar_combine_menu",
    content_url: "dashboard/combine_menu.htm",
    needs: [],
    route: null
  }, {
    element: "toolbar_download",
    content_url: "dashboard/download_icon.htm",
    needs: [TOUR_ELEMENTS.TB_COMBINE_MENU],
    route: null
  }, {
    element: "toolbar_favorites",
    content_url: "dashboard/favourites_icon.htm",
    needs: [TOUR_ELEMENTS.TB_COMBINE_MENU],
    route: null
  }, {
    element: "toolbar_notifications",
    content_url: "dashboard/notifications_icon.htm",
    needs: [],
    route: null
  }, {
    element: "toolbar_account",
    content_url: "dashboard/user_account_icon.htm",
    needs: [],
    route: null
  }, {
    element: "toolbar_account_logout",
    content_url: "dashboard/user_logout.htm",
    needs: [TOUR_ELEMENTS.TB_USER],
    route: null
  }, {
    element: "toolbar_account_user",
    content_url: "dashboard/user_actual.htm",
    needs: [TOUR_ELEMENTS.TB_USER],
    route: null
  }, {
    element: "toolbar_account_lic_exp",
    content_url: "dashboard/account_license_expiry.htm",
    needs: [TOUR_ELEMENTS.TB_USER],
    route: null
  }, {
    element: "toolbar_account_account",
    content_url: "dashboard/account_actual.htm",
    needs: [TOUR_ELEMENTS.TB_USER],
    route: null
  }, {
    element: "toolbar_account_schedule",
    content_url: "dashboard/account_schedule.htm",
    needs: [TOUR_ELEMENTS.TB_USER],
    route: null
  }, {
    element: "toolbar_account_license",
    content_url: "dashboard/account_license.htm",
    needs: [TOUR_ELEMENTS.TB_USER],
    route: null
  }, {
    element: "toolbar_account_language",
    content_url: "dashboard/user_language.htm",
    needs: [TOUR_ELEMENTS.TB_USER],
    route: null
  }, {
    element: "toolbar_account_version",
    content_url: "dashboard/user_app_version.htm",
    needs: [TOUR_ELEMENTS.TB_USER],
    route: null
  }, {
    element: "toolbar_help",
    content_url: "dashboard/help_icon.htm",
    needs: [TOUR_ELEMENTS.TB_COMBINE_MENU],
    route: null
  }, {
    element: "no_element",
    content_url: "dashboard/menu_tour_finish.htm",
    needs: [],
    route: null
  }
];

// dashboard steps
export const dashboard_steps: ITourStep[] = [
  // dashboard - special steps
  {
    element: "skipped_description_id",
    content_url: "{de:Erklärung aller wichtigen Elemente des Dashboards.};{en:Explanation of all important elements of the dashboard.}",
    needs: [],
    route: "/app"
  },
  // dashboard - content and commands
  {
    element: "no_element",
    content_url: "dashboard/dashboard_tour_intro.htm",
    needs: [],
    route: null
  }, {
    element: "timerange",
    content_url: "dashboard/date_range.htm",
    needs: [],
    route: null
  }, {
    element: "data-tiles",
    content_url: "dashboard/measurement_segment.htm",
    needs: [],
    route: null
  }, {
    element: "data-tile-accoustic",
    content_url: "dashboard/acoustic_data.htm",
    needs: [],
    route: null
  }, {
    element: "data-tile-flow",
    content_url: "dashboard/flow_data.htm",
    needs: [],
    route: null
  }, {
    element: "data-tile-pressure",
    content_url: "dashboard/pressure_data.htm",
    needs: [],
    route: null
  }, {
    element: "data-tile-temperature",
    content_url: "dashboard/temperature_data.htm",
    needs: [],
    route: null
  }, {
    element: "condition-tiles",
    content_url: "dashboard/status_segments.htm",
    needs: [],
    route: null
  }, {
    element: "condition-tile-correlation",
    content_url: "dashboard/correlation_status.htm",
    needs: [],
    route: null
  }, {
    element: "condition-tile-transmission",
    content_url: "dashboard/transmission_status.htm",
    needs: [],
    route: null
  }, {
    element: "condition-tile-gsm-signal",
    content_url: "dashboard/signal_strength.htm",
    needs: [],
    route: null
  }, {
    element: "condition-tile-battery",
    content_url: "dashboard/battery_health.htm",
    needs: [],
    route: null
  }, {
    element: "map-tile",
    content_url: "dashboard/map.htm",
    needs: [],
    route: null
  }, {
    element: "no_element",
    content_url: "dashboard/dashboard_tour_finish.htm",
    needs: [],
    route: null
  }
];

// favorite steps
export const favorite_steps: ITourStep[] = [
  // favorite - special steps
  {
    element: "skipped_description_id",
    content_url: "{de:Erklärung aller wichtigen Elemente eines Favoriten-Eintrages.};{en:Explanation of all important elements of a favorite entry.}",
    needs: [],
    route: null
  },
  // favorite - content and commands
  {
    element: "no_element",
    content_url: "favorites/favorite_tour_intro.htm",
    needs: [],
    route: null
  }, {
    element: "fav_side_wrap",
    content_url: "favorites/favorite_tour_wrap.htm",
    needs: [TOUR_ELEMENTS.TB_FAVORITES],
    route: null
  }, {
    element: "fav_side_add",
    content_url: "favorites/favorite_tour_add.htm",
    needs: [TOUR_ELEMENTS.TB_FAVORITES],
    route: null
  }, {
    element: "fav_side_status",
    content_url: "favorites/favorite_tour_status.htm",
    needs: [TOUR_ELEMENTS.TB_FAVORITES],
    route: null
  }, {
    element: "fav_side_details",
    content_url: "favorites/favorite_tour_details.htm",
    needs: [TOUR_ELEMENTS.TB_FAVORITES],
    route: null
  }, {
    element: "no_element",
    content_url: "favorites/favorite_tour_det_view.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_name",
    content_url: "favorites/favorite_tour_det_name.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_desc",
    content_url: "favorites/favorite_tour_det_desc.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_prio",
    content_url: "favorites/favorite_tour_det_prio.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_type",
    content_url: "favorites/favorite_tour_det_type.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_pipe_det",
    content_url: "favorites/favorite_tour_det_pipe.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_leak_det",
    content_url: "favorites/favorite_tour_det_leak.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_states",
    content_url: "favorites/favorite_tour_det_states.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_leak_pos",
    content_url: "favorites/favorite_tour_det_leak_pos.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_created",
    content_url: "favorites/favorite_tour_det_created.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_main",
    content_url: "favorites/favorite_tour_det_main.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_related",
    content_url: "favorites/favorite_tour_det_related.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_attach",
    content_url: "favorites/favorite_tour_det_attach.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_share",
    content_url: "favorites/favorite_tour_det_share.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_complete",
    content_url: "favorites/favorite_tour_det_complete.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_close",
    content_url: "favorites/favorite_tour_det_cancel.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_delete",
    content_url: "favorites/favorite_tour_det_delete.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "fav_det_update",
    content_url: "favorites/favorite_tour_det_update.htm",
    needs: [TOUR_ELEMENTS.FAV_DETAILS],
    route: null
  }, {
    element: "no_element",
    content_url: "favorites/favorite_tour_finish.htm",
    needs: [],
    route: null
  }
];
